<template>
  <div class="product-warning">
    <PageHeader
      title="产品预警设置"
      icon
      icon-content="库存不足 : 现有库存+在途库存<库存下限<br />
库存积压 : 现有库存+在途库存>库存上限<br />
库存滞销预警 : 产品入库后多少天内没有销售过的产品进行预警<br />
效期预警 : 产品有效期到期前多少天进行预警"
      center
    />
    <div class="flex p-lr-20 SearchBox_30 m-tb-20" style="flex: 1">
      <div class="set_value flex min-border j-between a-center m-right-10" style="width:auto">
        <div class="p-lr-5 f12 wsn">滞销预警设置</div>
        <el-input v-model="setValue.UnsalableDays" class="BlockBox" />
        <span class="p-lr-10 f12">天</span>
      </div>
      <div class="set_value flex min-border j-between a-center  m-right-10" style="width:auto">
        <div class="p-lr-5 f12 wsn">效期预警设置</div>
        <el-input v-model="setValue.ValidityDays" class="BlockBox" />
        <span class="p-lr-10 f12">天</span>
      </div>
      <div class="set_value flex min-border j-between a-center  m-right-10" style="width:auto">
        <div class="p-lr-5 f12 wsn">库存上限</div>
        <el-input v-model="setValue.InventoryUp" class="BlockBox" />
      </div>
      <div class="set_value flex min-border j-between a-center  m-right-10" style="width:auto">
        <div class="p-lr-5 f12 wsn">库存下限</div>
        <el-input v-model="setValue.InventoryDown" class="BlockBox" />
      </div>
      <div class="flex j-between a-center  m-lr-10">
        <el-button size="small" @click.stop="EditMaterialStockWarning">批量保存</el-button>
      </div>
      <el-input
        v-model="formSearch.KeyWords"
        size="medium"
        placeholder="请输入内容"
        class="rule-input-edit"
      >
        <!-- <template slot="prepend">
          <div class="pointer">
            <i class="el-icon-s-operation" />
            <span>高级搜索</span>
          </div>
        </template> -->
        <div slot="append" class="cursor">
          <div
            @click="searchGetTableDataAsync"
          >
            搜 索
          </div>
        </div>

      </el-input>

    </div>

    <div class="p-lr-20">
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        class="TableModel8"
        :header-cell-style="{ background: 'rgb(247,248,252)' }"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        :max-height="$minCommon.SetTableHeight(340)"
        row-key="IDX"
        border
        :fit="true"
        @selection-change="change"
      >
        <!-- @select-all="selectAll"
        @select="select" -->
        <af-table-column :reserve-selection="true" fixed="left" type="selection" width="40" />
        <el-table-column prop="InvCode" label="物料编号" show-overflow-tooltip align="center" />
        <el-table-column prop="InvName" label="物料名称" show-overflow-tooltip align="center" />
        <el-table-column prop="BrandName" label="品牌" show-overflow-tooltip align="center" />
        <el-table-column prop="MaterialTypeName" label="物料类型" show-overflow-tooltip align="center" />
        <el-table-column prop="Specification" label="规格/型号" show-overflow-tooltip align="center" />
        <el-table-column prop="MRCStockUnitN" label="单位" show-overflow-tooltip align="center" />
        <el-table-column prop="StockNum" label="可用数量" show-overflow-tooltip />
        <el-table-column prop="InventoryDown" label="库存下限报警数" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <input v-model="scope.row.InventoryDown" style="width:100px" :disabled="scope.row.disabled" type="number">
          </template>
        </el-table-column>
        <el-table-column prop="InventoryUp" label="库存上限报警数" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <input v-model="scope.row.InventoryUp" style="width:100px" :disabled="scope.row.disabled" type="number">
          </template>
        </el-table-column>
        <el-table-column prop="UnsalableDays" label="滞销预警天数" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <input v-model="scope.row.UnsalableDays" style="width:100px" :disabled="scope.row.disabled" type="number">
          </template>
        </el-table-column>
        <el-table-column prop="ValidityDays" label="效期预警天数" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <input v-model="scope.row.ValidityDays" style="width:100px" :disabled="scope.row.disabled" type="number">
          </template>
        </el-table-column>
        <el-table-column prop="set" label="操作" width="50px" align="center">
          <template slot-scope="score">
            <el-link type="primary" @click.stop="EditMaterialStockWarning1(score.row)">保存</el-link>
          </template>
        </el-table-column>

      </el-table>
      <div class="pagination-container">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          :current-page.sync="currentPage"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Index from '@/minxin/management/product-warning'
export default Index
</script>
<style>

</style>
<style scoped lang="scss">
@import "@/style/search.scss";
</style>

