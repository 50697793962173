import PageHeader from '@/components/PageHeader'
import Adsearch from '@/components/Adsearch'
import tableMixin from '@/minxin/table-minxin'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
// import root from '@/minxin/root' root
export default {
  name: 'ProductWarning',
  mixins: [tableMixin],
  components: {
    PageHeader,
    Adsearch,
    ElImageViewer
  },
  data() {
    return {
      formSearch: { KeyWords: '', BrandName: '', InvCode: '', InvName: '', PageIndex: 1, PageSize: 15 },
      tableData: [],
      setValue: {
        Type: 1, // 1:  单个修改/批量修改 2：全量修改
        Idxs: '', // 保存库存预警批量idx 字符串 逗号分割 （如果type 为1
        UnsalableDays: '', // 滞销预警天数
        ValidityDays: '', // 效期预警天数
        InventoryUp: '', // 库存上限
        InventoryDown: '' //  库存下限
      },
      selects: []
    }
  },
  watch: {
    'setValue.Idxs'(a) {
      if (a) {
        return this.setValue.Type = 1
      }
      if (!a) {
        return this.setValue.Type = 2
      }
    }
  },
  methods: {
    change(e) {
      this.selects = e
    },
    // 单个保存
    EditMaterialStockWarning1(row) {
      console.log(row)
      const data = {
        Type: 1, // 1:  单个修改/批量修改 2：全量修改
        Idxs: row.IDX, // 保存库存预警批量idx 字符串 逗号分割 （如果type 为1
        UnsalableDays: row.UnsalableDays, // 滞销预警天数
        ValidityDays: row.ValidityDays, // 效期预警天数
        InventoryUp: row.InventoryUp, // 库存上限
        InventoryDown: row.InventoryDown //  库存下限
      }
      console.log(data)
      this.$api.EditMaterialStockWarning(data).then(res => {
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        return this.$message.success('保存成功')
      }).catch(err => {
        return this.$message.error('错误：' + err)
      })
    },
    // 保存
    EditMaterialStockWarning() {
      if (this.selects.length <= 0) return this.$message.error('没有任何选中')
      if (!this.setValue.UnsalableDays) return this.$message.error('滞销预警天数不能为空')
      if (!this.setValue.ValidityDays) return this.$message.error('效期预警天数不能为空')
      if (!this.setValue.InventoryUp) return this.$message.error('库存上限不能为空')
      if (!this.setValue.InventoryDown) return this.$message.error('库存下限不能为空')
      this.selects.map((item, index) => {
        if (index !== this.selects.length - 1) {
          return this.setValue.Idxs += `${item.IDX},`
        }
        return this.setValue.Idxs += `${item.IDX}`
      })
      this.$api.EditMaterialStockWarning(this.setValue).then(res => {
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.selects = []
        return this.$message.success('保存成功')
      }).catch(err => {
        return this.$message.error('错误：' + err)
      })
    },
    async getTableDataAsync(page, pageSize) {
      // api/QueryStockMaterialList]
      this.formSearch.PageIndex = page || 1
      this.formSearch.PageSize = this.tablePageSize
      const response = await this.$api.GetStockWarningList(this.formSearch)
      console.log('response', response)
      if (response.RetCode !== '0') return this.$message.error('错误：' + response.Message)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      return response
    }
  }
}
